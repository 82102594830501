import { forwardRef } from "react";

import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  useColorModeValue,
  useTheme
} from "@chakra-ui/react";
import { css, Global } from "@emotion/react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ThemeColors } from "../styles/theme";

interface DatePickerProps {
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  isClearable?: boolean;
  inputProps?: InputProps;
  showMonthYearPicker?: boolean;
}

export const AvDatePicker = ({
  selectedDate,
  onChange,
  minDate,
  maxDate,
  dateFormat = "MM/dd/yyyy",
  isClearable = false,
  inputProps = {},
  showMonthYearPicker = false
}: DatePickerProps) => {
  return (
    <>
      <DatePickerStyles />
      <Box display="flex" width="100%" className="chakra-datepicker" alignItems="center">
        <DatePicker
          selected={selectedDate}
          onChange={onChange}
          customInput={<CustomInput inputProps={inputProps} />}
          renderCustomHeader={(props) => <CustomHeader {...props} showMonthYearPicker={showMonthYearPicker} />}
          popperClassName="chakra-datepicker__popper"
          calendarClassName="chakra-datepicker__calendar"
          dateFormat={dateFormat}
          minDate={minDate}
          maxDate={maxDate}
          placeholderText={inputProps.placeholder}
          isClearable={isClearable}
          showPopperArrow={false}
          showMonthYearPicker={showMonthYearPicker}
        />
      </Box>
    </>
  );
};

const DatePickerStyles = () => {
  const theme = useTheme();

  const calendarBgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const textColor = useColorModeValue("black", "white");
  const hoverBgColor = useColorModeValue("gray.100", "gray.600");
  const selectedBgColor = theme.colors.brand.primary;

  return (
    <Global
      styles={css`
        .chakra-datepicker__popper {
          z-index: 2;
        }
        .chakra-datepicker__calendar {
          background-color: ${calendarBgColor};
          border: 1px solid ${borderColor};
          border-radius: 8px;
          padding: 0;
          overflow: hidden;
        }
        .react-datepicker__header {
          border-bottom: none;
          padding: 0.5rem 0;
          color: black;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          margin-bottom: 0.5rem;
        }
        .react-datepicker__month {
          margin: 0;
        }

        .react-datepicker__month-text,
        .react-datepicker__day,
        .react-datepicker__day-name {
          width: 2rem;
          height: 2rem;
          line-height: 2rem;
          margin: 0.2rem;
          color: ${textColor};
          font-size: 0.9rem;
          border-radius: 8px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        .react-datepicker__day-name {
          font-weight: normal;
          font-size: 0.8rem;
          margin-bottom: 0.2rem;
        }
        .react-datepicker__month-text:hover,
        .react-datepicker__day:hover {
          background-color: ${hoverBgColor};
        }
        .react-datepicker__month-text--selected,
        .react-datepicker__day--selected {
          background-color: ${selectedBgColor};
          color: white;
        }
        .react-datepicker__current-month {
          color: black;
          font-size: 0.9rem;
          margin: 0;
        }
        .react-datepicker__navigation {
          top: 6px;
        }
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__day--keyboard-selected {
          background-color: ${selectedBgColor};
          color: white;
        }
        .react-datepicker__day--outside-month {
          color: lightgray;
        }
        .react-datepicker__close-icon {
          margin-right: 1rem;
        }
        .react-datepicker__close-icon::after {
          background-color: ${selectedBgColor};
        }
      `}
    />
  );
};

interface CustomHeaderProps {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  decreaseYear: () => void;
  increaseYear: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  showMonthYearPicker?: boolean;
}

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  showMonthYearPicker
}: CustomHeaderProps) => {
  const displayedDate = format(date, "MMMM yyyy");
  const handlePrev = showMonthYearPicker ? decreaseYear : decreaseMonth;
  const handleNext = showMonthYearPicker ? increaseYear : increaseMonth;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" px={1.5} color="black">
      <IconButton
        icon={<ChevronLeftIcon />}
        onClick={handlePrev}
        isDisabled={prevMonthButtonDisabled}
        size="sm"
        aria-label="Previous"
        variant="ghost"
        color="black"
        _hover={{ bg: ThemeColors.brand.primary }}
      />
      <Box fontSize="sm" fontWeight="semibold">
        {displayedDate}
      </Box>
      <IconButton
        icon={<ChevronRightIcon />}
        onClick={handleNext}
        isDisabled={nextMonthButtonDisabled}
        size="sm"
        aria-label="Next"
        variant="ghost"
        color="black"
        _hover={{ bg: ThemeColors.brand.primary }}
      />
    </Box>
  );
};

interface CustomInputProps extends InputProps {
  inputProps?: InputProps;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>((props, ref) => {
  const { inputProps = {}, ...rest } = props;
  const theme = useTheme();

  // If inputProps.isDisabled is true, remove event handlers
  const effectiveProps = inputProps.isDisabled ? null : rest;

  return (
    <InputGroup display="flex" alignItems="center">
      <InputLeftElement pointerEvents="none">
        <CalendarIcon color={theme.colors.gray} />
      </InputLeftElement>
      <Input
        type="text"
        placeholder={inputProps?.placeholder || "Select Date..."}
        ref={ref}
        aria-label="Date Picker Input"
        bg={theme.colors.white}
        variant="outline"
        {...inputProps}
        {...effectiveProps}
      />
    </InputGroup>
  );
});
CustomInput.displayName = "CustomInput";
